import { GetServerSideProps } from 'next';
import React from 'react';

import BasePage from 'components/BasePage/BasePage';
import { useApp } from 'contexts/appContext';
import { FooterType } from 'models/footer';
import { IContentPage } from 'models/page';
import { ContentPageService } from 'services/contentPageService';
import { fetcher } from 'services/httpRequestor';

// This gets called on every request
export const getServerSideProps: GetServerSideProps = async ({
  query,
  req,
}) => {
  const pageService = new ContentPageService(fetcher(req.headers.cookie));
  try {
    const { data: page, status } = await pageService.getOneByPageName('', {
      query,
    });
    if (status === 404) {
      return {
        props: {
          statusCode: 404,
        },
        notFound: true,
      };
    }

    // Pass data to the page via props
    return { props: { page } };
  } catch (error) {
    return {
      props: {
        statusCode: error.status,
      },
    };
  }
};

interface HomeProps {
  host: string;
  page: IContentPage;
  statusCode?: number;
}

const Home: React.FC<HomeProps> = ({ host, page, statusCode }) => {
  const { displayBlackBackgroundCampaign } = useApp();
  if (displayBlackBackgroundCampaign) {
    return (
      <BasePage
        displayCallToAction={false}
        page={{ ...page, page_blocks: [] }}
        host={host}
        statusCode={statusCode}
        footerType={FooterType.COMPACT}
      />
    );
  }
  return <BasePage page={page} host={host} statusCode={statusCode} />;
};

export default Home;
