import React, { useCallback, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import BubbleBackgroundSection from 'components/BubbleBackgroundSection/BubbleBackgroundSection';
import RowWithOffsetCol from 'components/RowWithOffsetCol/RowWithOffsetCol';

import s from './StandardPageHeader.module.scss';

interface IProps {
  imageURL?: string;
  imageAlt?: string;
  backgroundImageURL?: string;
  mobileImageURL?: string;
}
const StandardPageHeader: React.FC<IProps> = ({
  children,
  imageURL,
  backgroundImageURL,
  mobileImageURL,
  imageAlt,
  ...props
}) => {
  const hasImage = useMemo(() => imageURL, [imageURL]);

  const renderContainer = useCallback(() => {
    return (
      <Container>
        {hasImage ? (
          <Row>
            <Col
              className="py-4 pb-5 pb-sm-10 pb-lg-8 d-flex flex-column justify-content-center align-items-start"
              lg={5}
            >
              {children}
            </Col>
            <Col className="pt-2 pt-lg-0 max-vh-50 d-none d-lg-block" lg={7}>
              <div className="ml-n3 mr-n5">
                <img
                  className={cx(s['page-banner-image'], 'd-block')}
                  src={imageURL}
                  alt={imageAlt}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <RowWithOffsetCol
            colProps={{ className: 'd-flex flex-column align-items-center' }}
          >
            {children}
          </RowWithOffsetCol>
        )}
      </Container>
    );
  }, [children, hasImage, imageAlt, imageURL]);

  return (
    <>
      {backgroundImageURL ? (
        <>
          <style jsx>{`
            .page-banner {
              background-image: ${backgroundImageURL
                ? `url(${backgroundImageURL})`
                : 'none'};
            }

            @media (max-width: 768px) {
              .page-banner {
                background-image: url(${mobileImageURL || backgroundImageURL});
              }
            }
          `}</style>
          <div
            className={cx(
              `overflow-hidden pt-4 ${s.header} d-flex flex-column justify-content-center background-cover page-banner`,
              { 'pb-4 pb-sm-9': !hasImage },
            )}
          >
            {renderContainer()}
          </div>
        </>
      ) : (
        <BubbleBackgroundSection
          className={cx(
            `overflow-hidden pt-4 ${s.header} d-flex flex-column justify-content-center background-cover`,
            { 'pb-4 pb-sm-9': !hasImage },
          )}
          variant="secondary"
          mobileImageURL={mobileImageURL}
          backgroundImageURL={backgroundImageURL}
          {...props}
        >
          {renderContainer()}
        </BubbleBackgroundSection>
      )}
    </>
  );
};

export default StandardPageHeader;
