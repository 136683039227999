import Link from 'next/link';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import cx from 'classnames';

import { useApp } from 'contexts/appContext';

import s from './HomePageHeader.module.scss';

interface IProps {
  mobileImageURL?: string;
  imageURL?: string;
  imageAlt?: string;
}

const HomePageHeader: React.FC<IProps> = ({
  children,
  mobileImageURL,
  imageURL,
  imageAlt,
  ...props
}) => {
  const {
    blackBackgroundCampaignConfiguration,
    displayBlackBackgroundCampaign,
  } = useApp();
  if (displayBlackBackgroundCampaign) {
    return (
      <section
        aria-label={imageAlt}
        className={cx(
          'bg-black pt-5 pb-11 flex-grow-1',
          s['page-banner'],
          s['page-black-background-campaign'],
        )}
        {...props}
      >
        <Container>
          <div className="d-flex flex-column text-white align-items-center">
            <div
              className={cx(
                'd-flex flex-column text-white align-items-center mb-4',
                s['slide-down'],
              )}
              dangerouslySetInnerHTML={{
                __html: blackBackgroundCampaignConfiguration?.heading || '',
              }}
            />
            <Link
              href={`/campaign/${blackBackgroundCampaignConfiguration?.slug}`}
              passHref
            >
              <Button className={s['slide-down-delay']} variant="outline-white">
                DONATE NOW
              </Button>
            </Link>
          </div>
        </Container>
      </section>
    );
  }

  return (
    <>
      <style jsx>{`
        .page-banner {
          background-image: ${imageURL ? `url(${imageURL})` : 'none'};
        }

        @media (max-width: 768px) {
          .page-banner {
            background-image: url(${mobileImageURL || imageURL});
          }
        }
      `}</style>
      <section
        aria-label={imageAlt}
        className={cx('page-banner pt-5 pb-11', s['page-banner'])}
        {...props}
      >
        <Container>
          <Row>
            <Col lg={4} className="offset-lg-2">
              {children}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomePageHeader;
