import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import renderBlocks from 'utils/renderBlocks';

import Card from 'components/Card/Card';
import HeadWithSocialMedia from 'components/HeadWithSocialMedia/HeadWithSocialMedia';
import HomePageHeader from 'components/HomePageHeader/HomePageHeader';
import LogoIcon from 'components/Icons/Logo/Logo';
import Page404Content from 'components/Page404Content/Page404Content';
import StandardPageHeader from 'components/StandardPageHeader/StandardPageHeader';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { useApp } from 'contexts/appContext';
import { FooterType } from 'models/footer';
import { IContentPage } from 'models/page';

import MainLayout from '../../layouts/MainLayout';

import s from '../../assets/scss/pages.module.scss';

interface IProps {
  page?: IContentPage;
  statusCode?: number;
  host: string;
  footerType?: FooterType;
  displayCallToAction?: boolean;
}

const BasePage: React.FC<IProps> = ({
  page,
  displayCallToAction = true,
  host,
  statusCode,
  footerType,
}) => {
  const router = useRouter();
  const { featuredDonationCampaignSlug, festiveTreeSlug } = useApp();
  const [transcriptIsVisible, setTranscriptIsVisible] = useState(false);
  const [modalWatchVideoIsOpen, setModalWatchVideoIsOpen] = useState(false);

  const toggleTranscript = useCallback(() => {
    setTranscriptIsVisible(!transcriptIsVisible);
  }, [transcriptIsVisible]);

  const handleClickWatchTheFilm = useCallback(() => {
    setModalWatchVideoIsOpen(true);
  }, []);

  const handleClickCallToAction = useCallback(() => {
    router
      .push(
        {
          pathname: '/campaign/[slug]',
          query: {
            slug: featuredDonationCampaignSlug,
          },
        },
        `/campaign/${featuredDonationCampaignSlug}`,
      )
      .then(() => window.scrollTo(0, 0));
  }, [featuredDonationCampaignSlug, router]);

  const isHomePage = useMemo(() => !page?.slug, [page?.slug]);

  const renderHeaderContent = useCallback(
    (h1Variant = 'black', pVariant = 'black', btnVariant = 'primary') => {
      const textIsCentralized = !page?.heading_image?.url;
      return (
        <>
          {page?.heading && !page?.heading_image_left?.url ? (
            <h1
              className={cx(`text-${h1Variant} w-100`, {
                'text-center': textIsCentralized,
              })}
            >
              {page?.heading}
            </h1>
          ) : (
            <img
              className="w-100 mt-0 mt-md-5 mt-lg-0 mb-0"
              src={page?.heading_image_left?.url}
              alt={page?.heading_image_alt}
            />
          )}

          {page?.sub_heading && (
            <p
              data-content="page_sub_heading"
              className={cx(`text-${pVariant} mt-4 w-100`, {
                'text-center': textIsCentralized,
              })}
            >
              {page?.sub_heading}
            </p>
          )}
          {page?.video && (
            <Button
              onClick={handleClickWatchTheFilm}
              variant={btnVariant}
              className="mt-3"
            >
              Watch the film
            </Button>
          )}
        </>
      );
    },
    [
      handleClickWatchTheFilm,
      page?.heading,
      page?.heading_image?.url,
      page?.heading_image_alt,
      page?.heading_image_left?.url,
      page?.sub_heading,
      page?.video,
    ],
  );

  const hasHeader = useMemo(() => {
    return page?.heading || page?.heading_image || page?.sub_heading;
  }, [page?.heading, page?.heading_image, page?.sub_heading]);

  if ((statusCode && statusCode >= 400) || !page) {
    return <Page404Content />;
  }

  const {
    title_tag,
    description_meta,
    page_blocks,
    donation_campaigns,
    heading_image,
    mobile_heading_image,
    heading_image_alt,
    video,
    slug,
    transcript,
    thank_you_messages,
    board_members,
    footer,
    articles,
    published_datetime,
    keywords,
    canonical_url,
    gos_status,
    statistics,
    xmas_messages,
    heading_background_image,
  } = page;

  const showCustomTreeHeader = !isEmpty(router.query.decoration);

  return (
    <>
      <HeadWithSocialMedia
        title={title_tag}
        description={description_meta}
        thumbnailURL={
          slug === festiveTreeSlug
            ? '/imgs/static_tree_with_decorations.jpg'
            : heading_image?.url
        }
        url={slug ? `${host}/${slug}` : host}
        published_time={published_datetime}
        keywords={keywords}
        canonical_url={canonical_url}
      />
      <MainLayout
        onClickCallToAction={
          displayCallToAction ? handleClickCallToAction : undefined
        }
        displayFooter={!!footerType || !!footer}
        footerType={footerType || (footer?.name as FooterType)}
        footerHubspotID={footer?.hubspot_form?.form_id}
      >
        {showCustomTreeHeader && (
          <h2 className="bg-primary text-white text-center py-4">
            Thanks for your support!
          </h2>
        )}
        {!showCustomTreeHeader && hasHeader && (
          <>
            {isHomePage ? (
              <HomePageHeader
                mobileImageURL={mobile_heading_image?.url}
                imageURL={heading_image?.url}
                imageAlt={heading_image_alt}
              >
                <LogoIcon height="8em" width="8em" />
                {renderHeaderContent('white', 'white', 'outline-white')}
              </HomePageHeader>
            ) : (
              <StandardPageHeader
                backgroundImageURL={heading_background_image?.url}
                mobileImageURL={mobile_heading_image?.url}
                imageURL={heading_image?.url}
                imageAlt={heading_image_alt}
              >
                {renderHeaderContent('danger')}
              </StandardPageHeader>
            )}
          </>
        )}

        {!!page_blocks?.length &&
          renderBlocks(
            page_blocks,
            donation_campaigns,
            thank_you_messages,
            board_members,
            articles,
            gos_status,
            statistics,
            xmas_messages,
            !!hasHeader,
            showCustomTreeHeader,
          )}
      </MainLayout>
      {video && (
        <Modal
          size="xl"
          dialogClassName={cx(s['modal-video'], 'px-3')}
          centered
          show={modalWatchVideoIsOpen}
          onHide={() => setModalWatchVideoIsOpen(false)}
        >
          <div>
            <VideoPlayer
              videoURL={video.url}
              videoMimetype={video.mimetype}
              onClickReadTranscript={toggleTranscript}
            />
            {transcriptIsVisible && transcript && (
              <Card className="mt-3">
                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: transcript }} />
                </Card.Body>
              </Card>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BasePage;
