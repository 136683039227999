import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ImPause, ImPlay2 } from 'react-icons/im';

import cx from 'classnames';

import Card from 'components/Card/Card';

import s from './VideoPlayer.module.scss';

export interface VideoPlayerProps {
  videoURL: string;
  videoMimetype: string;
  className?: string;
  withLabel?: boolean;
  onClickReadTranscript?: () => void;
}
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoURL,
  videoMimetype,
  className,
  onClickReadTranscript,
  withLabel,
  ...props
}) => {
  const [playing, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef?.current) {
      videoRef!.current!.addEventListener('pause', () => {
        setIsPlaying(false);
      });
      videoRef!.current!.addEventListener('play', () => {
        setIsPlaying(true);
      });
    }
  }, [videoRef]);

  const togglePlayVideo = () => {
    if (!videoRef?.current) {
      return;
    }
    if (playing) {
      setIsPlaying(false);
      videoRef.current.pause();
    } else {
      setIsPlaying(true);
      videoRef.current.play();
    }
  };
  return (
    <Card
      className={cx(
        s['full-width-image-wrapper'],
        'overflow-hidden',
        className,
      )}
      {...props}
    >
      {/* eslint-disable-next-line */}
      <video width="100%" ref={videoRef} controls preload="none">
        <source src={videoURL} type={videoMimetype} />
      </video>
      <div className="text p-3 bg-primary">
        <div className="d-flex justify-content-between">
          <Button
            onClick={togglePlayVideo}
            variant="link"
            className="text-secondary p-0 mr-2 d-flex"
          >
            {playing ? (
              <>
                <ImPause size="30px" className="mr-2" />
                {withLabel && (
                  <h3 className="text-secondary">Pause the film</h3>
                )}
              </>
            ) : (
              <>
                <ImPlay2 size="30px" className="mr-2" />
                {withLabel && (
                  <h3 className="text-secondary">Watch the film</h3>
                )}
              </>
            )}
          </Button>
          {onClickReadTranscript && (
            <Button
              className="text-white p-0"
              onClick={onClickReadTranscript}
              variant="link"
              href="#"
            >
              Read the video transcript
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default VideoPlayer;
